const theme = {
  font: {
    primary: `'Work Sans', sans-serif`,
    secondary: `'Work Sans', sans-serif`,
  },
  font_size: {
    small: 'font-size: 13px; line-height: 16px',
    regular: 'font-size: 26px; line-height: 30px',
    large: 'font-size: 30px; line-height: 36px',
    xlarge: 'font-size: 60px; line-height: 60px',
  },
  color: {
    white: {
      regular: '#F5F5F5',
      dark: '#F1E7DA',
    },
    black: {
      regular: '#000000',
      lighter: '#ABA8AF',
      light: '#564F62',
    },
    primary: '#CA3A2E',
    secondary: '#E8B631',
  },
  screen: {
    xs: '575px',
    sm: '767px',
    md: '991px',
    lg: '1024px',
  },
};

export default theme;
